/* eslint-disable @typescript-eslint/member-delimiter-style */
import { i18n, isBolivia, isChile } from '../i18n'
import { FavoriteProduct } from '../types'
import { noResultsTexts } from './constants'
import { ErrorSource, reportError, Severity } from './sentry'

export const capitalizeFirstLetter = (str = '') => {
  return str.charAt(0).toUpperCase() + str.substring(1).toLowerCase()
}

export const capitalize = (str = '') => {
  const splitStr = str.split(' ')
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = capitalizeFirstLetter(splitStr[i])
  }
  return splitStr.join(' ')
}

export function toNumber(n: number | string, isCurrency = false): string {
  if (n === null || n === undefined) return ''

  if (typeof n === 'string') return n

  return i18n.numberFormatter(n, isCurrency)
}

export const toCurrency = (num: number | string, useFloatFormat?: boolean) => {
  const amount = useFloatFormat ? parseInt(`${num}`, 10) / 100 : num
  return `${i18n.currencySymbol}${isBolivia ? ' ' : ''}${toNumber(amount, true)}`
}

export const parseCurrency = (currency: string) => {
  const numbersOnly = currency.replace(/[^0-9]/g, '')
  return numbersOnly ? parseInt(numbersOnly) : 0
}

export const ALGOLIA_PRODUCTS_NO_CUSTOMER_GROUPS_FILTER = 'objectId:NO_CUSTOMER_GROUPS_FILTER'
export const createAlgoliaGlobalFilters = ({
  customerGroups,
  customerBranchOfficeId,
  customerChannelMkt,
  customerChannel,
  customerPriceList,
  customerShowBagInBoxProducts,
  isSuggested = false,
}: {
  customerGroups: number[]
  customerBranchOfficeId?: string
  customerChannelMkt?: string
  customerChannel?: string
  customerPriceList: number
  customerShowBagInBoxProducts: boolean
  isSuggested?: boolean
}) => {
  // Prevent showing products when customer has no customerGroups available
  if (customerGroups?.length === 0) return ALGOLIA_PRODUCTS_NO_CUSTOMER_GROUPS_FILTER
  let groupsFilter = ''

  if (isChile && customerGroups?.length) {
    customerGroups.forEach((item, index) => {
      groupsFilter = `${groupsFilter} ${index === 0 ? '' : 'OR'} groups=${item}`
    })
    return `(${groupsFilter.trim()})${
      customerBranchOfficeId
        ? ` AND NOT lockedBranches:${customerBranchOfficeId} AND NOT hiddenBranches:${customerBranchOfficeId}`
        : ''
    }${customerPriceList ? ` AND (prices.list:${customerPriceList})` : ''}${customerShowBagInBoxProducts ? '' : ' AND NOT pimPackaging:"BAG IN BOX"'}${customerBranchOfficeId ? ` AND (NOT hiddenFor.branchOffice:${customerBranchOfficeId} OR NOT hiddenFor.channel:${customerChannel})` : ''}${isSuggested ? ` AND (NOT notAvailableFor.branchOffice:${customerBranchOfficeId} OR NOT notAvailableFor.channel:${customerChannel}) AND NOT notAvailableBranches:${customerBranchOfficeId}` : ''}`
  }

  if (isBolivia) {
    const timeStampNow = Math.floor(+new Date() / 1000)
    // filter that allows to show products and bundles with availability for the customer
    if (customerBranchOfficeId && customerChannelMkt) {
      return `prices.list:${customerPriceList} AND availability.startDate <= ${timeStampNow} AND availability.endDate >= ${timeStampNow} AND (availability.branchOffice:${customerBranchOfficeId}__${customerChannelMkt
        .replaceAll(' ', '%20')
        .replaceAll('(', '%28')
        .replaceAll(')', '%29')} OR availability.branchOffice:all) ${
        customerBranchOfficeId
          ? ` AND NOT lockedBranches:${customerBranchOfficeId} AND NOT hiddenBranches:${customerBranchOfficeId}`
          : ''
      }${customerShowBagInBoxProducts ? '' : ' AND NOT pimPackaging:"BAG IN BOX"'}${customerBranchOfficeId ? ` AND (NOT hiddenFor.branchOffice:${customerBranchOfficeId} OR NOT hiddenFor.channel:${customerChannel})` : ''}${isSuggested ? ` AND (NOT notAvailableFor.branchOffice:${customerBranchOfficeId} OR NOT notAvailableFor.channel:${customerChannel}) AND NOT notAvailableBranches:${customerBranchOfficeId}` : ''}`
    }
    return `${customerPriceList ? `prices.list:${customerPriceList}` : ''} ${customerBranchOfficeId ? ` AND NOT lockedBranches:${customerBranchOfficeId}` : ''}${customerShowBagInBoxProducts ? '' : ' AND NOT pimPackaging:"BAG IN BOX"'}${customerBranchOfficeId ? ` AND (NOT hiddenFor.branchOffice:${customerBranchOfficeId} OR NOT hiddenFor.channel:${customerChannel})` : ''}${isSuggested ? ` AND (NOT notAvailableFor.branchOffice:${customerBranchOfficeId} OR NOT notAvailableFor.channel:${customerChannel}) AND NOT notAvailableBranches:${customerBranchOfficeId}` : ''}`
    // customerGroups.forEach((item, index) => {
    //   groupsFilter = `${groupsFilter} ${index === 0 ? '' : 'OR'} groups=${item}` ----> Functionality disabled until embol fix their product groups
    // })
    // return groupsFilter
  }

  const error = {
    message: `Cannot read algolia filters params: customerBranchOfficeId=${customerBranchOfficeId}; customerGroups=${customerGroups}`,
    response: '',
  }
  reportError({
    message: `on Create Algolia Global filters: ${error.message}`,
    level: Severity.Critical,
    page: 'general util/strings',
    source: ErrorSource.WEB_APP,
    metadata: { error, response: error.response },
  })
  console.error('Cannot read algolia filters params')

  return `${customerBranchOfficeId ? `NOT lockedBranches:${customerBranchOfficeId} AND NOT hiddenBranches:${customerBranchOfficeId}` : ''}${customerShowBagInBoxProducts ? '' : ' AND NOT pimPackaging:"BAG IN BOX"'}${customerBranchOfficeId ? ` AND (NOT hiddenFor.branchOffice:${customerBranchOfficeId} OR NOT hiddenFor.channel:${customerChannel})` : ''}${isSuggested ? ` AND (NOT notAvailableFor.branchOffice:${customerBranchOfficeId} OR NOT notAvailableFor.channel:${customerChannel}) AND NOT notAvailableBranches:${customerBranchOfficeId}` : ''}`
}

export const ALGOLIA_PROMOTIONS_NO_RESULTS_FILTER = 'objectId:NO_RESULTS_FILTER'
export const createAlgoliaPromotionsFilter = (promotionSkus: number[]): string => {
  let promotionsFilter = ''
  if (promotionSkus && promotionSkus.length) {
    promotionSkus.forEach((item, index) => {
      promotionsFilter = `${promotionsFilter} ${index === 0 ? '' : 'OR'} sku=${item}`
    })
    return `${promotionsFilter}`
  }
  return ALGOLIA_PROMOTIONS_NO_RESULTS_FILTER
}

export const ALGOLIA_FAVORITES_NO_RESULTS_FILTER = 'objectId:NO_RESULTS_FILTER'
export const createAlgoliaFavoritesFilter = (favoritesProducts: FavoriteProduct[]): string => {
  if (favoritesProducts && favoritesProducts.length)
    return favoritesProducts.reduce(
      (acc, currItem, index) => `${acc} ${index > 0 ? 'OR' : ''} sku=${currItem.productId}`,
      '',
    )

  return ALGOLIA_FAVORITES_NO_RESULTS_FILTER
}

export const getFirstWord = (words: string): string => words.replace(/ .*/, '')

export const randomString = (length: number) => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890'

  let result = ''

  for (let i = 0; i < length; i++) {
    const char = chars[Math.floor(Math.random() * (chars.length - 1))]
    result += char
  }

  return result
}

export const extendWordIfMoreThanOneUnit = (originalWord: string, keyword: string, units: number) =>
  units > 1 ? `${originalWord}${keyword}` : originalWord

export const getByNounType = (singular: string, plural: string) => (value: number) => (value > 1 ? plural : singular)

export const readableFileSize = (bytes, decimals = 1) => {
  const divider = 1000

  if (bytes < divider) {
    return `${bytes} B`
  }

  const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const decimalsDivider = 10 ** decimals

  let i = -1
  do {
    bytes /= divider
    ++i
  } while (Math.round(bytes * decimalsDivider) / decimalsDivider >= divider && i < units.length - 1)

  return `${bytes.toFixed(decimals)} ${units[i]}`
}

export const normalizePhoneNumber = (value: string) => {
  if (value.length > 1) {
    const newValue = value.replace(/\s/g, '')
    return `${newValue[0]} ${newValue.substring(1)}`
  }
  return value
}

export const singularOrPlural = (
  quantity: number,
  singular: string,
  plural: string,
  format?: (quantity: number) => string,
) => {
  if (format) {
    return quantity === 1 ? `${format(quantity)} ${singular}` : `${format(quantity)} ${plural}`
  }
  return quantity === 1 ? `${quantity} ${singular}` : `${quantity} ${plural}`
}

export const getNoResultsText = ({
  isFavorites,
  hasFavorites,
  hasProducts,
  category,
}: {
  isFavorites?: boolean
  hasFavorites?: boolean
  hasProducts?: boolean
  category?: string
}): string => {
  if (isFavorites) {
    if (!hasFavorites) {
      return noResultsTexts.NO_FAVORITES
    }
    if (category && !hasProducts) {
      return `${noResultsTexts.NO_FAVORITES} en ${category}`
    }

    return noResultsTexts.NO_MORE_FAVORITES
  }

  return noResultsTexts.NO_SEARCH
}
